import { createStore } from 'vuex'

export default createStore({
  state: {
    websock: null,
    serverURL: "192.168.3.88:8888",
    statusMsg: "",
    captureData: "",
    serviceData: {service: "wechat", version: "8.0.2"},
    serverStatus: "未连接"
  },
  mutations: {
    startServer(state, payload) {
      state.websock = new WebSocket("ws://" + state.serverURL);
      state.websock.onmessage = payload.onmessage;
      state.websock.onopen = payload.onopen;
      state.websock.onerror = payload.onerror;
      state.websock.onclose = payload.onclose;
    },
    setServerURL(state, payload) {
      state.serverURL = payload.serverURL;
    },
    setStatusMsg(state, payload) {
      state.statusMsg = payload.msg;
    },
    setCaptureData(state, payload) {
      state.captureData = payload.captureData;
    },
    setServerStatus(state, payload) {
      state.serverStatus = payload.status;
    },
    setServiceData(state, payload) {
      state.serviceData = payload.serviceData;
    }
  },
  actions: {
    connect({commit}) {
      let onmessageFun = function(event) {
        let parsed = JSON.parse(event.data);
        if (parsed["requestID"]) return;
        commit({
          type: 'setCaptureData',
          captureData: event.data
        });
      };
      let onopenFun = function() {
        commit({
          type: 'setServerStatus',
          status: '已连接'
        });
        commit({
          type: 'setStatusMsg',
          msg: 'connected'
        });
      }
      let onerrorFun = function() {
        commit({
          type: 'setServerStatus',
          status: '出错啦'
        });
        commit({
          type: 'setStatusMsg',
          msg: 'error'
        });
      }
      let oncloseFun = function() {
        commit({
          type: 'setServerStatus',
          status: '已关闭'
        });
        commit({
          type: 'setStatusMsg',
          msg: 'closed'
        });
      }
      commit({
          type: 'startServer',
          onmessage: onmessageFun,
          onopen: onopenFun,
          onerror: onerrorFun,
          onclose: oncloseFun
        });
    },
    sendMsg({state, commit}, payload) {
      if (state.websock === null) {
          console.log("Server 是 null 消息发送失败");
          commit({
            type: 'setStatusMsg',
            msg: 'Server 是 null 消息发送失败'
          });
          return;
      }
      if (state.websock.readyState === WebSocket.OPEN) {
          state.websock.send(payload.msg);
          commit({
            type: 'setStatusMsg',
            msg: 'sent: ' + payload.msg
          });
      } else {
          console.log("Server 状态不是 Open 消息发送失败 - " + payload.msg);
          commit({
            type: 'setStatusMsg',
            msg: "Server 状态不是 Open 消息发送失败 - " + payload.msg
          });
      }
    }
  },
  modules: {
  }
})
