<template>
  <div class="connect">
    <a-divider>手机服务器信息</a-divider>
    <a-space direction="vertical">
      <a-row type="flex" justify="center">
        <a-col :span="12"> 服务器地址: </a-col>
        <a-col :span="12">
          <a-typography-paragraph v-model:content="serverURL" editable />
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="12">服务器状态: </a-col>
        <a-col :span="12">{{ serverStatus }}</a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="24">
          <a-space>
            <a-button type="primary" @click="connect()"
              >连接手机服务器</a-button
            >
            <a-button type="primary" @click="capture()"
              >抓取当前页面数据</a-button
            >
          </a-space>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="24">
          <a-space>
            <a-upload
              v-model:file-list="fileList"
              :multiple="true"
              :action="uploadFiles"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                导入截图、结点树和标注文件
              </a-button>
            </a-upload>
          </a-space>
        </a-col>
      </a-row>
    </a-space>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { UploadOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, ref } from "vue";
import { message } from "ant-design-vue/es";

export default defineComponent({
  name: "Connect",
  setup() {
    const store = useStore();
    const serverStatus = computed(() => {
      return store.state.serverStatus;
    });
    const serverURL = computed({
      get() {
        return store.state.serverURL;
      },
      set(value) {
        store.commit({
          type: "setServerURL",
          serverURL: value,
        });
      },
    });
    const fileList = ref([]);
    const capture = () => {
      store.dispatch({
        type: "sendMsg",
        msg: "{'type': 'capture'}",
      });
    };
    const captureData = ref({});
    const serviceData = ref({});
    const uploadFiles = (file) => {
      return new Promise(() => {
        const reader = new FileReader();
        if (file.name.endsWith(".json")) {
          if (file.name.indexOf("service") > -1) {
            reader.onload = () => {
              // 当读取完成时，内容只在`reader.result`中
              serviceData.value = JSON.parse(reader.result);
              trySetCaptureData();
            };
          } else {
            reader.onload = () => {
              // 当读取完成时，内容只在`reader.result`中
              captureData.value.root = JSON.parse(reader.result);
              trySetCaptureData();
            };
          }
          reader.readAsText(file);
        } else if (file.name.endsWith(".png")) {
          reader.onload = () => {
            if (reader.result.startsWith("data:image/png;base64,")) {
              captureData.value.img = reader.result.substring(22);
              trySetCaptureData();
            } else {
              message.error("图片格式不正确");
            }
          };
          reader.readAsDataURL(file);
        } else {
          message.error("文件格式不正确");
        }
      });
    };
    const trySetCaptureData = () => {
      if (captureData.value.img && captureData.value.root) {
        store.commit({
          type: "setCaptureData",
          captureData: JSON.stringify(captureData.value),
        });
      }
      store.commit({
        type: "setServiceData",
        serviceData: serviceData.value,
      });
    };
    const connect = () => {
      store.dispatch({
        type: "connect",
      });
    };
    return {
      serverStatus,
      serverURL,
      connect,
      capture,
      uploadFiles,
      fileList,
    };
  },
  components: {
    UploadOutlined,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.connect {
  margin-bottom: 20px;
}
</style>
