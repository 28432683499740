<template>
  <a-divider>信息标注与查看</a-divider>
  <a-space direction="vertical">
    <a-row type="flex" justify="center">
      <a-space>
        <a-button type="primary" @click="exportScreen">导出截图</a-button>
        <a-button type="primary" @click="exportRoot">导出结点树</a-button>
        <a-button type="primary" @click="exportService">导出标注文件</a-button>
      </a-space>
    </a-row>
    <a-row type="flex" justify="center">
      <a-col :span="8">
        <canvas ref="canvas" width="432" height="920" id="canvas"
          >不支持canvas</canvas
        >
      </a-col>
      <a-col :span="8">
        <a-space direction="vertical">
          <div>
            📜 列表 ↕ 可滚动 🔹 列表项 🖼️ 图片 <br />👆 可点击 🤏 可长按 ✅
            已选中 ❎ 未选中 🆎 有文本 🔣 有描述
          </div>
          <a-input-search
            v-model:value="searchValue"
            style="margin-bottom: 8px"
            placeholder="Search"
          />
          <a-tree
            showLine
            :expandedKeys="expandedKeys"
            :selectedKeys="selectedKeys"
            :auto-expand-parent="autoExpandParent"
            :tree-data="root"
            @select="onSelect"
            @expand="onExpand"
          >
            <template #title="{ title }">
              <span v-if="title.indexOf(searchValue) > 0">
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span>
                {{
                  title.substr(title.indexOf(searchValue) + searchValue.length)
                }}
              </span>
              <span v-else>
                {{ splitTitle(title)[0] }}
                <b>{{ splitTitle(title)[1] }}</b>
                {{ splitTitle(title)[2] }}
              </span>
            </template>
          </a-tree>

          <a-table
            :dataSource="tableValue"
            :columns="tableColumns"
            :pagination="false"
            class="ant-table-striped"
            size="middle"
            :row-class-name="
              (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
            "
          />
        </a-space>
      </a-col>

      <a-col :span="8">
        <JsonEditorVue
          class="json-editor"
          v-model="serviceData"
          :show-btns="true"
          :expandedOnStart="true"
          :options="jsonEditorOptions"
        ></JsonEditorVue>
      </a-col>
    </a-row>
  </a-space>
</template>

<script>
import { message } from "ant-design-vue/es";
import JsonEditorVue from "json-editor-vue3";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue";
import { saveAs } from "file-saver";

const splitTitle = (text) => {
  let re = /^(.+?)(\[.+\]|\{.+\}|\[.+\]\{.+\})(:(?:\[[0-9,]+\]){2}.*)$/s;
  let result = re.exec(text);
  if (result) {
    return [result[1], result[2], result[3]];
  } else {
    return [text, "", ""];
  }
};
const getTitle = (node) => {
  let ret = node["@class"];
  if (node["@text"]) {
    ret += "[" + node["@text"] + "]";
  }
  if (node["@content-desc"]) {
    ret += "{" + node["@content-desc"] + "}";
  }
  if (node["@bounds"]) {
    ret += ":" + node["@bounds"];
  }
  if (node["@isList"]) {
    ret += " 📜";
  }
  if (node["@scrollable"]) {
    ret += " ↕";
  }
  if (node["@isListItem"]) {
    ret += " 🔹";
  }
  if (node["@class"].indexOf("ImageView") > -1) {
    ret += " 🖼️";
  }
  if (node["@clickable"]) {
    ret += " 👆";
  }
  if (node["@checkable"]) {
    if (node["@checked"]) {
      ret += " ✅";
    } else {
      ret += " ❎";
    }
  }
  if (node["@long-clickable"]) {
    ret += " 🤏";
  }
  if (node["@text"]) {
    ret += " 🆎";
  }
  if (node["@content-desc"]) {
    ret += " 🔣";
  }
  return ret;
};
const formatTree = (node) => {
  let isOldVersion = false;
  if (!node["@nodeid"]) {
    isOldVersion = true;
  }
  if (node["@nodeid"]) {
    node["key"] = node["@nodeid"];
    node["title"] = getTitle(node);
  } else {
    if (isOldVersion) {
      node["@nodeid"] =
        node["@pid"] + "|" + node["@index"] + ";" + node["@class"];
      node["key"] = node["@nodeid"];
      node["title"] = getTitle(node);
    }
  }
  if (node["node"]) {
    let n = node["node"];
    node["children"] = [];
    if (Array.isArray(n)) {
      n.forEach((v, i) => {
        if (isOldVersion) {
          v["@pid"] = node["@nodeid"];
          v["@index"] = i;
        }
        node["children"].push(formatTree(v));
      });
    } else {
      if (isOldVersion) {
        n["@index"] = 0;
        n["@pid"] = node["@nodeid"];
      }
      node["children"].push(formatTree(n));
    }
    delete node["node"];
  }
  return node;
};
const parseTree = (data) => {
  if (!data || data === "")
    return {
      root: { key: "0", children: [], title: "暂无数据" },
      img: "",
    };
  let d = JSON.parse(data);
  return {
    root: formatTree(d.root),
    img: "data:image/jpg;base64," + d.img,
  };
};

const findNodeByNodeId = (tree, nodeId) => {
  if (tree["@nodeid"] === nodeId) {
    return tree;
  }
  if (tree["children"]) {
    for (let i = 0; i < tree["children"].length; i++) {
      let v = tree["children"][i];
      let ret = findNodeByNodeId(v, nodeId);
      if (ret) return ret;
    }
  }
  return null;
};
const inRect = (rect, x, y) => {
  return rect[0] <= x && x <= rect[2] && rect[1] <= y && y <= rect[3];
};
const getRect = (node) => {
  if (node["@bounds"]) {
    let re = /\d+/g;
    let rect = node["@bounds"].match(re);
    return [
      parseInt(rect[0]),
      parseInt(rect[1]),
      parseInt(rect[2]),
      parseInt(rect[3]),
    ];
  }
  return null;
};
const findNodesByPos = (tree, posx, posy) => {
  let ret = [];
  let rect = getRect(tree);
  if (rect) {
    if (inRect(rect, posx, posy)) {
      ret.push(tree);
    }
    if (tree["children"]) {
      for (let i = 0; i < tree["children"].length; i++) {
        ret.push.apply(ret, findNodesByPos(tree["children"][i], posx, posy));
      }
    }
  }
  return ret;
};
const isSameArray = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  let a1 = arr1.map((v) => v["@nodeid"]);
  let a2 = arr2.map((v) => v["@nodeid"]);
  return a1.join("") === a2.join("");
};
const customCell = (cell) => {
  return {
    onClick: () => {
      copyTextToClipboard(cell.value, cell.value);
    },
  };
};

const copyTextToClipboardFallBack = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};
const copyTextToClipboard = (text, msg) => {
  if (!navigator.clipboard) {
    copyTextToClipboardFallBack(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      message.info(msg + "已复制!");
    },
    function (err) {
      message.error(msg + "复制失败!");
      console.error("Async: Could not copy text: ", err);
    }
  );
};

let dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

const tableColumns = [
  {
    title: "属性",
    dataIndex: "key",
  },
  {
    title: "值（点击可复制）",
    dataIndex: "value",
    ellipsis: true,
    customCell: customCell,
  },
];

const dataURLToBlob = (dataURL) => {
  var BASE64_MARKER = ";base64,";
  var parts;
  var contentType;
  var raw;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = decodeURIComponent(parts[1]);

    return new Blob([raw], { type: contentType });
  }

  parts = dataURL.split(BASE64_MARKER);
  contentType = parts[0].split(":")[1];
  raw = window.atob(parts[1]);
  var rawLength = raw.length;
  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export default defineComponent({
  props: {
    data: String,
  },
  setup(props) {
    const { data } = toRefs(props);
    const store = useStore();
    const root = computed(() => {
      return [parseTree(data.value).root];
    });
    const originRoot = computed(() => {
      try {
        let j = JSON.parse(data.value);
        if (j.root) return j.root;
      } catch (err) {
        message.error("结点数据缺失或格式有误");
        console.error(err);
      }
      return "";
    });
    const scale = ref(0.4);
    const canvas = ref(null);
    const canvasClickListener = (event) => {
      let realX = event.offsetX / scale.value;
      let realY = event.offsetY / scale.value;
      let nodes = findNodesByPos(root.value[0], realX, realY);
      activeNodes.value = nodes;
    };
    onMounted(() => {
      canvas.value.addEventListener("click", canvasClickListener, false);
      generateList(root.value);
    });
    const activeNodeIndex = ref(-1);
    const activeNodes = ref([]);

    const drawCanvas = function (canvas, imageSrc, scale) {
      if (!canvas) return;
      let context = canvas.getContext("2d");
      var img = new Image();
      img.onload = () => {
        canvas.setAttribute("width", Math.floor(img.width * scale));
        canvas.setAttribute("height", Math.floor(img.height * scale));
        context.drawImage(
          img,
          0,
          0,
          Math.floor(img.width * scale),
          Math.floor(img.height * scale)
        );
        if (activeNodeIndex.value >= 0) {
          let rect = getRect(activeNodes.value[activeNodeIndex.value]);
          context.strokeStyle = "red";
          context.lineWidth = 4;
          context.strokeRect(
            rect[0] * scale,
            rect[1] * scale,
            (rect[2] - rect[0]) * scale,
            (rect[3] - rect[1]) * scale
          );
        }
      };
      img.src = imageSrc;
    };

    const img = computed(() => {
      return parseTree(data.value).img;
    });
    const tableValue = computed(() => {
      if (
        activeNodes.value.length > 0 &&
        activeNodeIndex.value >= 0 &&
        activeNodeIndex.value < activeNodes.value.length
      ) {
        let node = activeNodes.value[activeNodeIndex.value];
        return createTableValue(node);
      }
      return [];
    });
    const createTableValue = (node) => {
      let ret = [];
      Object.keys(node).map((v) => {
        if (v[0] == "@") {
          ret.push({
            key: v.substring(1),
            value: String(node[v]),
          });
        }
      });
      return ret;
    };
    watch(root, function () {
      dataList = [];
      generateList(root.value);
    });
    watch(activeNodes, function (newV, oldV) {
      if (root.value["key"] === "0") return;
      if (newV.length === 0) return;
      if (isSameArray(newV, oldV)) {
        activeNodeIndex.value = activeNodeIndex.value - 1;
        if (activeNodeIndex.value < 0) {
          activeNodeIndex.value = activeNodes.value.length - 1;
        }
      } else {
        activeNodeIndex.value = activeNodes.value.length - 1;
      }
      let node = newV[activeNodeIndex.value];
      expandedKeys.value = [node["@nodeid"]];
      selectedKeys.value = [node["@nodeid"]];
      autoExpandParent.value = true;
      copyTextToClipboard(
        '"node":"' + node["@nodeid"] + '"',
        "选中节点的NodeId"
      );
      drawCanvas(canvas.value, img.value, scale.value);
    });

    const selectedKeys = ref([]);
    watch(img, (newV) => {
      activeNodes.value = [];
      activeNodeIndex.value = -1;
      drawCanvas(canvas.value, newV, scale.value);
    });

    const expandedKeys = ref([]);
    const searchValue = ref("");
    const autoExpandParent = ref(true);

    const onSelect = (keys) => {
      selectedKeys.value = keys;
      let node = findNodeByNodeId(root.value[0], keys[0]);
      if (node) {
        activeNodeIndex.value = 0;
        autoExpandParent.value = true;
        expandedKeys.value = keys;
        activeNodes.value = [node];
      } else {
        activeNodeIndex.value = -1;
        activeNodes.value = [];
      }
    };
    const onExpand = (keys) => {
      expandedKeys.value = keys;
      autoExpandParent.value = false;
    };

    watch(searchValue, (value) => {
      const expanded = dataList
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, root.value);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      expandedKeys.value = expanded;
      searchValue.value = value;
      autoExpandParent.value = true;
      drawCanvas(canvas.value, img.value, scale.value);
    });
    const exportScreen = () => {
      let blob = dataURLToBlob(img.value);
      saveAs(blob, "Page.png");
    };
    const exportRoot = () => {
      let blob = new Blob([JSON.stringify(originRoot.value)], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "Page.json");
    };
    const exportService = () => {
      let blob = new Blob([JSON.stringify(serviceData.value, null, 2)], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "Service.json");
    };
    const serviceData = computed({
      get() {
        return store.state.serviceData;
      },
      set(v) {
        store.commit({
          type: "setServiceData",
          serviceData: v,
        });
      },
    });
    const jsonEditorOptions = {
      onValidationError: (errors) => {
        if (errors.length > 0) {
          message.error("JSON has ERROR:  " + errors[0].message);
        } else {
          message.success("JSON correct and SAVED！");
        }
      },
    };
    return {
      root,
      img,
      scale,
      canvas,
      selectedKeys,
      expandedKeys,
      searchValue,
      autoExpandParent,
      onSelect,
      onExpand,
      splitTitle,
      tableValue,
      tableColumns,
      exportScreen,
      exportRoot,
      exportService,
      serviceData,
      jsonEditorOptions,
    };
  },
  components: {
    JsonEditorVue,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#canvas {
  border: 1px solid black;
}
.json-editor {
  height: 100%;
}

.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>
