<template>
  <div class="home">
    <Connect />
    <Label :data="captureData"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Connect from '@/components/Connect.vue'
import Label from '@/components/Label.vue'

export default {
  name: 'Home',
  computed: {
    captureData() {
      return this.$store.state.captureData;
    },
    serviceData() {
      return this.$store.state.serviceData;
    }
  },
  components: {
    Connect,
    Label
  }
}
</script>
